/************************
* SectionHeading component
*/
.section-heading {
  margin: auto;
  margin-bottom: 5rem;
  max-width: 600px;
  min-width: 200px;
}

.section-heading__inner {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.section-heading__line {
  margin-right: 1.7rem;
  display: inline-block;
  width: 50px;
  height: 2.5px;
  background-color: var(--color-tertiary-2);
}

.section-heading__text {
  color: var(--color-tertiary-2);
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.section-heading__head {
  font-weight: 600;
  font-size: 49px;
  line-height: 120%;
  letter-spacing: -0.02em;
}

.section-heading__description {
  max-width: 450px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
}
