@import "./_variable.css";

.custom-btn {
  padding: 14px 20px;
  border-radius: 6px;
  text-align: center;
  color: var(--color-light);
  font-size: 19px;
}

.custom-btn--fill {
  background-color: var(--color-primary-dark);
  border: 2px solid var(--color-primary-dark);
}

.custom-btn--outline {
  background-color: transparent;
  border: 2px solid var(--color-light);
}
