:root {
  /* Colors */
  --color-primary: #2f7da9;
  --color-primary-2: #277fbe;
  --color-primary-3: #277abe;
  --color-primary-4: #0c7fbf;
  --color-primary-dark: #0c1740;
  --color-primary-light: #f4f7fa;
  --color-primary-light-1: #d6eaf5;
  --color-secondary: #48e5c2;
  --color-tertiary: #0c1740;
  --color-tertiary-2: #f49e4c;
  --color-light: #ffffff;
  --color-dark: #141a1f;
  --color-dark-2: #000000;
  --color-dark-3: #3a3a3a;

  /* Font sizes */
}
