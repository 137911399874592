/* eslint-disable */

/************************
* Hero section 
*/
.navigation {
  padding-top: 15px;
  padding-bottom: 20px;
}

.navbar__btns {
  display: flex;
  align-items: center;
}

.navbar__btns .nav-link {
  font-weight: 500;
}

#navbar__btn.btn__signup {
  margin-left: 20px;
  padding: 12px 40px;
  background-color: var(--color-primary-dark);
  color: var(--color-light);
  border-radius: 6px;
}

.manager .manager__hero {
  position: relative;
  overflow: hidden;
}

.manager .hero__gradient {
  width: 350px;
  height: 500px;
  position: absolute;
  background-color: #48e5c376;
  border-radius: 50%;
  position: absolute;
  top: 15%;
  left: 35%;

  /* Initializing free-flow animation */
  animation: free-flow 20s linear infinite;
  animation-delay: 3s;
}

.manager .hero__gradient::before {
  content: "";
  display: block;
  width: 600px;
  height: 600px;
  position: absolute;
  background-color: #37a1ce53;
  top: -15%;
  left: -150%;
  border-radius: 50%;

  /* Initializing free-flow animation */
  animation: free-flow 20s linear infinite;
  animation-delay: 1.5s;
  animation-duration: 10s;
}

.manager .hero__gradient::after {
  content: "";
  display: block;
  width: 400px;
  height: 400px;
  position: absolute;
  top: 10%;
  left: 100%;
  border-radius: 50%;
  opacity: 0.4;
  background-color: #f4874c96;

  /* Initializing free-flow animation */
  animation: free-flow 20s linear infinite;
  animation-delay: 0s;
}

.manager .hero__morphism {
  min-height: 100vh;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(150px);
  -webkit-backdrop-filter: blur(150px);
}

.manager__hero h1 {
  font-weight: 600;
  font-size: 61px;
  line-height: 71.37px;
  letter-spacing: -0.03em;
}

.manager__hero p {
  max-width: 80%;
  font-size: 20px;
  line-height: 34px;
}

/************************
* Percent section 
*/
.manager .percent__container {
  max-width: 65%;
  margin: auto;
  padding-bottom: 5rem;
  padding-top: 5rem;
}

.manager .percent__card {
  margin: 0 20px;
  text-align: center;
}

.manager .percent__header {
  margin-bottom: 2rem;
  font-family: Inter;
  font-size: 77px;
  font-weight: 700;
  line-height: 90px;
  letter-spacing: -0.03em;
}

/************************
* Program-benefits section 
*/
.manager .program-benefits {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: var(--color-primary-light);
  min-height: 20rem;
}

/************************
* Steps section 
*/
.manager .steps {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.manager .steps__icon {
  margin-right: 45px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: var(--color-primary-light-1);
  color: var(--color-primary-4);
  font-size: 25px;
  font-weight: 600;
}

.manager .steps__heading {
  padding-top: 10px;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
}

.manager .steps__list {
  /* margin: 0; */
  padding-left: 15px;
}

.manager .steps__heading,
.manager .steps__list {
  max-width: 700px;
}

/************************
* Call to action section 
*/
.manager .call-to-action__box {
  padding: 5rem 2rem;
  background-color: var(--color-primary-2);
  border-radius: 28px;
  text-align: center;
}

.manager .call-to-action__heading {
  margin: auto;
  max-width: 800px;
  font-size: 49px;
  font-weight: 600;
  line-height: 59px;
  letter-spacing: -0.02em;
}

.manager .call-to-action__body {
  margin: 35px auto;
  max-width: 500px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
}

.manager .call-to-action__heading,
.manager .call-to-action__body {
  color: var(--color-light);
}

.manager .call-to-action__btn {
  margin-top: 35px;
}

/************************
* Faq section
*/
.manager .faq {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

/************************
* Media query
*/
@media screen and (max-width: 1200px) {
  .manager__hero h1 {
    font-size: 51px;
  }

  .manager .percent__container {
    max-width: 100%;
  }
}

@media screen and (max-width: 1196px) {
  .manager__hero .container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 1000px) {
  .manager__hero h1 {
    font-size: 41px;
    font-weight: 700;
    line-height: 55.37px;
  }
}

@media screen and (max-width: 767px) {
  .percent .col-md:not(:last-child) {
    margin-bottom: 2rem;
    /* background: red; */
  }
}

@media screen and (max-width: 737px) {
  .manager__hero p {
    max-width: 100%;
  }

  .faq__questions {
    margin-top: -30px;
  }
}

@media screen and (max-width: 627px) {
  .manager .steps__icon {
    margin-right: 20px;
  }
}

@media screen and (max-width: 555px) {
  .manager .call-to-action__box {
    padding: 3rem;
  }

  .manager .call-to-action__heading {
    font-size: 40px;
  }

  .manager .call-to-action__btn {
    margin-top: 2rem;
  }
}

/************************
* Animation
*/
@keyframes free-flow {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}
