/* Footer section */

.footer-section {
  box-sizing: content-box;
  padding: 90px;
  background-color: #277fbe;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.footer-omnix {
  width: 27%;
}

.footer-logo {
  width: 50%;
  margin-top: 6%;
  margin-left: 10.5%;
  padding-bottom: 7%;
}

.footer-img {
  width: 100%;
}

.footer-text {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  margin-top: 2%;
  margin-left: 12%;
}

.footer-icons {
  margin: 10% 12%;
  /* cursor: pointer; */
}

.footer-icons a:not(:first-child) {
  margin-left: 6%;
}

.footer-header {
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  color: #fff;
}

.footer-lists {
  margin-top: 3%;
}
.footer-items {
  font-size: 16px;
  font-weight: 400;
}

.items {
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.9);
  line-height: 25.6px;
  margin: 12% 0;
  cursor: pointer;
}
.items a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 1060px) {
  .footer-section {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 1024px) {
  .footer-section {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 875px) {
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  .footer-items {
    font-size: 14px;
  }

  .footer-header {
    font-size: 16px;
  }

  .footer-lists {
    width: 30%;
  }

  .footer-omnix {
    width: 50%;
    font-size: 14px;
    line-height: 22.4px;
  }

  .footer-lists {
    margin-top: 4%;
    margin-left: 1.5%;
  }

  .footer-icons {
    position: absolute;
    width: 20%;
    bottom: -20%;
    left: -1.3%;
  }
}

@media screen and (max-width: 575px) {
  .footer-section {
    padding-bottom: 150px;
  }

  .footer-lists {
    width: 40%;
    margin-top: 10%;
    margin-left: 10%;
  }

  .footer-lists:last-child {
    margin-right: 50%;
  }

  .footer-omnix {
    width: 90%;
    margin-right: 11%;
    font-size: 14px;
    line-height: 22.4px;
  }

  .footer-icons {
    left: -2%;
    width: 50%;
  }

  .footer-icon a:not(:first-child) {
    margin-left: 8%;
  }
}
