/* Question Section */

.question-section {
  display: flex;
  justify-content: center;
  /* padding: 120px 170px; */
}

.question-right {
  width: 840px;
}

.expand {
  width: 20px;
}
.rest {
  width: 15px;
}

.question-header {
  font-size: 49px;
  font-weight: 600;
  color: #121212;
  line-height: 58.8px;
  letter-spacing: -2px;
  text-transform: capitalize;
}

.question-span {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #535e64;
  margin-top: 20px;
}

.question-link {
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
  color: #0c1740;
  line-height: 32px;
}

.question-drop {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
}

.question-dropdown {
  cursor: pointer;
  border-bottom: 2px solid #d0d0d0;
  padding: 20px;
}

.question-text {
  font-size: 20px;
  color: #121212;
  line-height: 32px;
  font-weight: 400;
}

.dropdown-span {
  font-size: 16px;
  color: #616161;
  line-height: 32px;
  font-weight: 400;
  margin-top: 20px;
  /* transform: translate(0, -2px); */
  transition: 0.2s ease-in-out;
  opacity: 0;
  display: none;
}

.dropdown--active {
  transform: translate(0, 2px);
  transform-origin: center;
  /* transition: 0.2s ease-in-out; */
  opacity: 1;
  display: block;
}

@media screen and (max-width: 705px) {
  .question-right {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .question-section {
    display: none;
    display: flex;
    flex-direction: column;
    /* border-bottom: 2px solid #48e5c2; */
  }

  .question-header {
    width: 80%;
    font-size: 33px;
    line-height: 39.6px;
    letter-spacing: -2px;
  }

  .question-span {
    margin: 15px 0;
    font-size: 16px;
    line-height: 16px;
  }

  .question-link {
    font-size: 16px;
    line-height: 16px;
  }

  .question-dropdown {
    margin-top: 0px;
    padding: 20px 0;
  }

  .question-text {
    font-size: 16px;
    line-height: 25.6px;
  }

  .dropdown-span {
    font-size: 16px;
    line-height: 25.6px;
    margin-top: 15px;
  }
}
