/************************
* Custom card
*/
.custom-card {
  margin-bottom: 20px;
  padding: 40px;
  min-height: 250px;
  background-color: var(--color-light);
  border-radius: 16px;
}

.custom-card__icon {
  height: 3.5rem;
}

.custom-card__header {
  margin: 20px 0;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
}

.custom-card__body {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
}
